.wrapper {
    width: 320px;
    padding: 20px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.avatar {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
}

.follow-btn {
    padding: 6px 16px;
}

.body {
    margin-top: 12px;
    color: #161823;
}

.nickname {
    font-size: 1.8rem;
}

.check {
    font-size: 1.4rem;
    color: #20d5ec;
    margin-left: 4px;
}

.name {
    font-size: 1.4rem;
}

.analytics {
    margin-top: 10px;
    font-size: 1.8rem;
}

.value ~ .value {
    margin-left: 20px;
}

.label {
    color: #161823bf;
}
