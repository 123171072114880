.wrapper {
    --search-border-radius: 92px;
    --search-height: 46px;
    --search-top-spacer: 9px;
    --search-button-width: 52px;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    width: 100%;
    height: var(--default-layout-header-height);
    box-shadow: 0px 1px 1px rgb(0 0 0 / 12%);
    background-color: var(--white);
}

.inner {
    height: 100%;
    width: var(--default-layout-width);
    padding: 0 var(--default-layout-horizontal-spacer);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo-link {
    display: flex;
}

// Actions
.actions {
    display: flex;
    align-items: center;
}

.more-btn {
    font-size: 2rem;
    margin-left: 28px;
    padding: 4px 8px;
    background-color: transparent;
    cursor: pointer;
}

.action-btn {
    position: relative;
    display: flex;
    font-size: 2.2rem;
    color: #161823;
    background-color: transparent;
    padding: 4px 10px;
    cursor: pointer;
}

.badge {
    position: absolute;
    top: -3px;
    right: 0px;
    padding: 0px 6px;
    height: 2rem;
    line-height: 2rem;
    border-radius: 10px;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--white);
    font-family: var(--font-family);
    background-color: var(--primary);
}

.user-avatar {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 14px;
    cursor: pointer;
}
