.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    width: var(--default-layout-width);
    max-width: 100%;
    padding: 0 var(--default-layout-horizontal-spacer);
    margin-top: var(--default-layout-header-height);
    display: flex;
}

.content {
    flex: 1;
}
