.menu-item {
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 1.8rem;
    font-weight: 700;
    border-radius: 4px;
    color: var(--text-color);
    transition: background-color ease-in-out 200ms;

    &.active {
        color: var(--primary);

        .icon {
            display: none;
        }

        .active-icon {
            display: flex;
        }
    }

    &:hover {
        background-color: rgba(22, 24, 35, 0.03);
    }
}

.title {
    margin-left: 10px;
}

.icon,
.active-icon {
    display: flex;
}

.active-icon {
    display: none;
}
